<template>
	<div class="ztnrbg">
	
	
		<div class="index_mv ztnr">
			<leftMeun></leftMeun>
			<div class="right_nr">
	<div class="list">
		<rightMeun></rightMeun>
		<div class="read_articles" v-if="list.length">
			<div class="newsList" style="flex: 1;">
			<news v-for="(li,index) in list" :key='index'  :msg="li" types="daxt" />
			</div>
		</div>
		<div v-else class="read_articles" >
			探索中
		</div>
		<div class="pages">
			<Page v-if="count>10" :total="count" @on-change='chan' />
		</div>
	</div>
	</div>
<newRightMeun></newRightMeun>
	</div>
	</div>
</template>
<script>
var _hmt = _hmt || [];
(function () {
	var hm = document.createElement('script');
	hm.src = 'https://hm.baidu.com/hm.js?819d608e55a54ab96615bb5d06bb6230';
	var s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(hm, s);
})();
</script>
<script>
	import news from '@/components/tpList.vue'
	import leftMeun from '@/components/leftMeun.vue'
	import rightMeun from '@/components/rightMeun.vue'
     import newRightMeun from '@/components/newRightMeun.vue'
	export default {
		components: {
			news,
			leftMeun,
			rightMeun,
            newRightMeun
		},
		data() {
			return {
				tzfs:'cont',
				list:[],
				page:1,
				count:0
			}
		},
		watch: {
			$route:'types'
		},
		
		methods: {
			chan(e){
				var that=this
				that.page=e
				that.lists()
			},
			lists() {
				var that=this,id
				if(that.$route.params.newsid==25){
					that.$api.news({
						page: that.page,
						row: 10,
						catid: that.$route.params.newsid
					}).then(res => {
						that.$emit('getLoad', true);
						that.list=res.data
						that.count=res.count
						// that.list=that.list.concat(res.data)
					}).catch((error) => {
						// error
						console.log(error)
					})
				}else{
					that.$api.news({
						page: that.page,
						row: 10,
						fid: that.$route.params.newsid
					}).then(res => {
						that.$emit('getLoad', true);
						that.list=res.data
						that.count=res.count
						// that.list=that.list.concat(res.data)
					}).catch((error) => {
						// error
						console.log(error)
					})
				}
				
			},
			types() {
				var that=this
				if(that.$route.params.newsid==25){
					that.tzfs='text'
					that.$api.news({
						page:1,
						row: 10,
						catid: that.$route.params.newsid
					}).then(res => {
						that.$emit('getLoad', true);
						that.list=res.data
						that.count=res.count
						// that.list=that.list.concat(res.data)
					}).catch((error) => {
						// error
						console.log(error)
					})
				}else{
					that.$api.news({
						page: 1,
						row: 10,
						fid: that.$route.params.newsid
					}).then(res => {
						that.$emit('getLoad', true);
						that.list=res.data
						that.count=res.count
						// that.list=that.list.concat(res.data)
					}).catch((error) => {
						// error
						console.log(error)
					})
				}
				
				
			}
		},
		mounted() {
			this.types()
		}
	}
</script>

<style>
	
</style>